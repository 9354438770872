<template>
  <div id="app">
   <router-view/>
  </div>
</template>

<script>


export default {
  name: 'App'
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Source+Sans+Pro:wght@300;400;600;700&display=swap');
  :root{

    --color-1: #00A175;
    --color-2:  #009084;
    --color-3: #007E94;
    --color-4: #565656;
    --color-5:  #FFFFFF;
    --color-6: #D3D3D3;
    --color-7: #e04747;
    --color-8: #868686;
    --color-9: #36398F;
    --color-10: #F8F8F8;

  
    --Sans: 'Source Sans Pro', sans-serif;
    --Open_Sans: 'Open Sans', sans-serif;
  }

@font-face {
  font-family: "Pangram-eb";
  src: url("./assets/font/PANGRAM-EXTRABOLD.ttf");
}

@font-face {
  font-family: "Pangram-m";
  src: url("./assets/font/PANGRAM-MEDIUM.ttf");
}


#app {
  margin: 0vw;
  min-height: 48VW;
  font-family: var(--Sans);
}


 input:focus-visible{
       outline: none !important;
}

input, textarea, select{
    padding-left: .5vw;
    outline: none
}


.dflx{
    display: flex;
}

.content_menu ul li, .menu_user, .notidv, .icon_editar, .icon_eliminar{
    cursor: pointer;
}

    input#validar-input{
       width: 5%;
       height: 1VW;
    }
    p.validar-p{
         font-size: 0.9375VW;
            color: #868686;
    }
button.btnEditar{
     background: var(--color-3);
     color: white;
     width: 7.9vw;
     text-align: center;
     border: none;
     height: 2vw;
     margin: 0vw;
     font-size: .8vw;
     margin-top: 1vw;
     margin-bottom: 1vw;
}
button.btnRgr{
 width: 7.916666666666666VW;
 height: 2.2916666666666665VW;
 border: 0px;
 color: white;
 background: #868686;
 margin-top: 2.7083333333333335VW;
 margin-left: 28vw;
}
p.btnRegresar{
      width: 7.916666666666666VW;
      padding: .5vw 2.5vw;
      height: 2.2916666666666665VW;
      border: 0px;
      color: white;
      background: #868686;
      margin-left: 19.6vw;
      margin-top: 2.7083333333333335VW;
      cursor: pointer;
}

p.btnRegresar2{
      width: 7.916666666666666VW;
      padding:  .3vw 2.5vw;
      height: 2.2916666666666665VW;
      border: 0px;
      color: white;
      background: #868686;
      margin-left: 26.5vw;
      margin-top: 2.7083333333333335VW;
      margin-right:0.625VW;
      cursor: pointer;
}

p.btnRegresar4{
      width: 7.916666666666666VW;
      padding:  .6vw 2.5vw;
      height: 2.2916666666666665VW;
      border: 0px;
      color: white;
      background: #868686;
      margin-left: 21.9vw;
      margin-top: 2.7083333333333335VW;
      margin-right:0.625VW;
      cursor: pointer;
}

p.btnRegresar3{
      width: 7.916666666666666VW;
      padding-top:  .6vw ;
      text-align: center;
      height: 2.2916666666666665VW;
      border: 0px;
      color: white;
      background: #868686;
    
      margin-top: 2.7083333333333335VW;
      cursor: pointer;
}
p.btnRegresar5{
      width: 7.916666666666666VW;
      padding-top:  .6vw ;
      text-align: center;
      height: 2.2916666666666665VW;
      border: 0px;
      color: white;
      background: #868686;
    margin-left: 1vw;
        margin-right: 1vw;
      margin-top: 2.7083333333333335VW;
      cursor: pointer;
}


p.dPDF{
      width: 8.5VW;
      padding:  .3vw 1vw;
      height: 2.2916666666666665VW;
      border: 0px;
      color: white;
      background: #868686;
      margin-top: 2.7083333333333335VW;
      cursor: pointer;
}

p.dPDF span{
    font-weight: 600;
}
/** MODAL DELETE */
    .modal_view{
        background-color:rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 59.25VW;
        position: fixed;
        top: 0vw;
        right: 0vw;
        
        
    }
    #modal_delete{
        background: white;
        width: 32.083333333333336VW;
        height: 16.25VW;
        position: absolute;
        top: 18vw;
        right: 32vw;
        padding: 0.8854166666666666VW;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    p.close_modal{
        color: #868686 ;
        text-align: right;
        cursor: pointer;
        font-size: 2vw;
        font-family: var(--Sans);
        font-weight: 300;
        top: 0vw;
        right: 1vw;
        position: absolute;
    }

    h3.title_modal{
        text-align: center;
         color: #868686 ;
          font-family: var(--Sans);
          font-size: 1.2vw;
          margin-top: 3vw;
    }

    p.message_modal{
        text-align: center;
         color: #868686 ;
         font-family: var(--Sans);
           font-size: .8vw;
           font-weight: 300;
           margin-top: 1vw;
           
    }

    .modal_btns{
        justify-content: center;
        margin-top: 3vw;
    }

    .modal_btns p{
        text-transform: uppercase;
        color: var(--color-5);
        font-size: 0.9375VW;
        font-weight: 300;
        text-align: left;
        padding: .5vw;
        width: 6vw;
        cursor: pointer;
        text-align: center;
        transition: all 300ms;
        background: #797979;
    }

    p.cancelar{
        margin-right: 2vw;
    }

    p.eliminar{
        margin-left:  2vw;
    }
    p.cancelar:hover{
        background: var(--color-1)
    }
    p.eliminar:hover{
         background: var(--color-7);
    }


/** MODAL */

/** MODAL add */
  
    #modal_add{
        background: white;
        width: 32.083333333333336VW;
        height: 16.25VW;
        position: absolute;
        top: 18vw;
        right: 32vw;
        padding: 0.8854166666666666VW;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    p.close_modal{
        color: #868686 ;
        text-align: right;
        cursor: pointer;
        font-size: 2vw;
        font-family: var(--Sans);
        font-weight: 300;
        top: 0vw;
        right: 1vw;
        position: absolute;
    }

    h3.title_add{
        text-align: center;
         color: #00A175 ;
          font-family: var(--Sans);
          font-size: 2.34375VW;
          margin-top: 3vw;
          font-weight: 300;
    }

    h3.title_add span{
        font-weight: 800;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }


    .modal_add_btns{
        justify-content: center;
        margin-top: 3vw;
    }

    .modal_add_btns p{
        text-transform: uppercase;
        color: var(--color-5);
        font-size: 0.9375VW;
        font-weight: 300;
        text-align: left;
        padding: .5vw;
        width: 8vw;
        cursor: pointer;
        text-align: center;
        transition: all 300ms;
        background: #00A175;

    }

    p.otro{
      
        margin-right: 2vw;
    }

     p.inicio{
        margin-left: 2vw;
    }

    
    p.otro:hover{
          background: var(--color-3)
    }

    p.editar:hover{
        background: var(--color-3)
    }

     p.inicio:hover{
          background: var(--color-3)
    }
  
  


/** MODAL */

/** MODAL add */
  
    #modal_ser{
        background: white;
        width: 32.083333333333336VW;
        height: 16.25VW;
        position: absolute;
        top: 18vw;
        right: 32vw;
        padding: 0.8854166666666666VW;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    p.close_modal{
        color: #868686 ;
        text-align: right;
        cursor: pointer;
        font-size: 2vw;
        font-family: var(--Sans);
        font-weight: 300;
        top: 0vw;
        right: 1vw;
        position: absolute;
    }

    h3.title_ser{
        text-align: center;
         color: #00A175 ;
          font-family: var(--Sans);
          font-size: 2.34375VW;
          margin-top: 3vw;
          font-weight: 300;
    }

    h3.title_ser span{
        font-weight: 800;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }


    .modal_ser_btns{
        justify-content: center;
        margin-top: 3vw;
    }

    .modal_ser_btns p{
        text-transform: uppercase;
        color: var(--color-5);
        font-size: 0.9375VW;
        font-weight: 300;
        text-align: left;
        padding: .5vw;
        width: 8vw;
        cursor: pointer;
        text-align: center;
        transition: all 300ms;
        background: #00A175;

    }

    p.otro{
      
        margin-right: 2vw;
    }

     p.inicio{
        margin-left: 2vw;
    }

    
    p.otro:hover{
          background: var(--color-3)
    }


     p.inicio:hover{
          background: var(--color-3)
    }
  
  


/** MODAL */

/** MODAL validacion */
  
    #modal_val{
        background: white;
        width: 32.083333333333336VW;
        height: 16.25VW;
        position: absolute;
        top: 18vw;
        right: 32vw;
        padding: 0.8854166666666666VW;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
  

    h3.title_val{
        text-align: center;
         color: #007E94 ;
          font-family: var(--Sans);
          font-size: 2.34375VW;
          margin-top: 3vw;
          font-weight: 300;
    }

    h3.title_val span{
        font-weight: 800;
        
    }


    .modal_val_btns{
        justify-content: center;
        margin-top: 3vw;
    }

    .modal_val_btns p{
        text-transform: uppercase;
        color: var(--color-5);
        font-size: 0.9375VW;
        font-weight: 300;
        text-align: left;
        padding: .5vw;
        width: 8vw;
        cursor: pointer;
        text-align: center;
        transition: all 300ms;
        background: #007E94;

    }

  


/** MODAL */


  /** MODAL edit */
  
    #modal_edit{
        background: white;
        width: 32.083333333333336VW;
        height: 16.25VW;
        position: absolute;
        top: 18vw;
        right: 32vw;
        padding: 0.8854166666666666VW;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    p.close_modal{
        color: #868686 ;
        text-align: right;
        cursor: pointer;
        font-size: 2vw;
        font-family: var(--Sans);
        font-weight: 300;
        top: 0vw;
        right: 1vw;
        position: absolute;
    }

    h3.title_edit{
        text-align: center;
         color: #00A175 ;
          font-family: var(--Sans);
          font-size: 2.34375VW;
          margin-top: 3vw;
          font-weight: 300;
    }

    h3.title_edit span{
        font-weight: 800;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }


    .modal_edit_btns{
        justify-content: center;
        margin-top: 3vw;
    }

    .modal_edit_btns p{
        text-transform: uppercase;
        color: var(--color-5);
        font-size: 0.9375VW;
        font-weight: 300;
        text-align: left;
        padding: .5vw;
        width: 8vw;
        cursor: pointer;
        text-align: center;
        transition: all 300ms;
        background: #00A175;

    }
.cp{
    cursor: pointer;
}
.mostrarcontra{
    margin-left: 1vw;
    width: 9vw !important;
}
    p.edit_otro{
      
        margin-right: 2vw;
    }

     
    p.edit_inicio{
        margin-left: 2vw;
    }

    
    p.edit_otro:hover{
          background: var(--color-3)
    }

   

     p.edit_inicio:hover{
          background: var(--color-3)
    }
  
  


/** MODAL */




/** MENSAJES DE EXITO/ERROR */
    .status_messages{
        margin-top: 1vw;
    }

    .msg p{
        color: white;
        width: 20vw;
        padding: .5vw;
        font-family: var(--Sans);
        font-size: 0.7333333333333334VW;
        font-style: normal;
    }
    .msg_success{
        background: var(--color-1);
        width: 20vw;
    }

    .msg_error p{
         background:var(--color-7);
        width: 20vw;
    }
    

    /** FIN MENSAJES DE EXITO/ERROR */


    /** NAVIGATE BUTTONS */
    #navigate-buttons{
    margin-left: 1.3vw;
    margin-top: 0.1vw;
     /* position: absolute; */
     /* right: 42vw;
      top: 39vw; 
      
       right: 15vw;
    top: 11.4vw;*/
    
   
    /*
    right: 21.7vw;
    top: 11.45vw; 
    */

    }

    #navigate-buttons button{
        border: 0px;
        background: var(--color-8);
       /* border-radius: 50%; */
        width: 1.5vw;
        height: 1.5vw;
        cursor: pointer;
        transition: all 300ms;
        color: white;
        font-size: 1vw;
        
    }#navigate-buttons button:hover{
       
        background: var(--color-2);
       
        
    }
    p.num-pages{
        color: #797979;
        margin-left: 1vw;
        margin-right: 1vw;
        padding-top: .2vw;
        font-size: .7vw;
    }

/** FIN NAVIGATE BUTTONS */




</style>
