import axios from "axios";

    
/*
post = 'kiC_UUf/ja0-dDu'
Get = 'wro_abo/P5Q-G2l'
Put = 'QVP_Rn1/fm3-99e'
Del = 'v9Z_P29/rwz-G3B'
*/

export default {

  login(user, headers){
    return axios.post('/kiC_UUf/ja0-dDu/login', user, headers);
  },
  login_barragan(user, headers){
    return axios.post('/kiC_UUf/ja0-dDu/login_barragan', user, headers);
  },

  getInfoUser(data, headers){
    return axios.post('/kiC_UUf/ja0-dDu/info_usr', data, {headers});
  },


  getAllRange(payload, headers){ //traer todos por rango de fecha
    return axios.post(`/kiC_UUf/ja0-dDu/${payload.option}`, payload.data, {headers});
  },


  getAllInfo(option, headers){
    return axios.get(`/wro_abo/P5Q-G2l/${option}`, {headers});
  },
  getInfoById(payload, headers){
    return axios.get(`/wro_abo/P5Q-G2l/${payload.option}/${payload.id}`, {headers});
  },
 
  search(data, headers){
    return axios.get(`/wro_abo/P5Q-G2l/buscar/${data.normalizedOpt}/${data.search}`, {headers});
  },

  searchByClient(data, headers){
    return axios.post(`/kiC_UUf/ja0-dDu/buscar/${data.option}`, data, {headers});
  },

  //metodos de eliminar, editar y agregar
  deleteItem(payload,token){
    return axios.delete(`/v9Z_P29/rwz-G3B/${payload.option}/delete`, {
        data: {
          id:payload.id
        },
        headers: {
          Authorization: token 
        } 
      });
  },
  
  editItem(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.put(`/QVP_Rn1/fm3-99e/${payload.option}/update`, payload.item, {headers} );
  },

  addItem(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.post(`/kiC_UUf/ja0-dDu/${payload.option}/create`, payload.item, {headers});
  },

  enviarMailContacto(data, headers){
    return axios.post('s43-wprDK6s_wfcs7/contacto', data, headers);
  },


  

};