import Vue from 'vue'
import Vuex from 'vuex'

import admin from './modules/admin'
import clientes from './modules/clientes'
import main from './modules/main'
import muestras from './modules/muestras'
import parametros from './modules/parametros'
import solserv from './modules/solserv'
import sucursal from './modules/sucursal'
import usuarios from './modules/usuarios'
import validaciones from './modules/validaciones'
import PDF from './modules/PDF'
import subcuenta from './modules/subcuenta'
import texto from './modules/texto'
import profile from './modules/profile'
import servicios_b from './modules/servicios_b'
import ticket from './modules/ticket'
import group_b from './modules/group_b'
import muestras_b from './modules/muestras_b'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin: admin,
    main:main,
    clientes:clientes,
    usuarios:usuarios,
    parametros: parametros,
    solserv:solserv,
    muestras:muestras,
    validaciones:validaciones,
    sucursal: sucursal,
    PDF:PDF,
    subcuenta:subcuenta,
    texto:texto,
    profile:profile,
    servicios_b:servicios_b,
    ticket:ticket,
    group_b: group_b,
    muestras_b: muestras_b


  }
})
